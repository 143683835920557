import React, { useState, useEffect, useRef } from 'react';
import { TouchableOpacity, Alert } from 'react-native';
import { Input, Spinner, View, Text, XStack, YStack, Popover } from 'tamagui';
import { AudioRecorderComponent } from './AudioRecorderComponent/AudioRecorderComponent';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import {
  faPaperPlane as solidPaperPlane,
  faChevronDown,
} from '@fortawesome/pro-solid-svg-icons';
import { faPaperPlane as lightPaperPlane } from '@fortawesome/pro-light-svg-icons/faPaperPlane';
import { useLanguageApi } from '../hooks/useLanguageApi';
import { useAudioApi } from '../hooks/useAudioApi';
import type { LanguageType } from '../ConversationAi';

interface SendMessageFormProps {
  sendMessage: (text: string) => void;
  audioSent: () => void;
  isLoading: boolean;
  translations?: { messageInputPlaceholder: string };
  apiBaseUrl?: string;
  audioUrl: string;
  preferencesUrl: string;
  style: {
    sendMessageStyles?: object;
    inputFieldStyles?: object;
    sendIconStyles?: object;
    spinnerStyles?: object;
    color?: string;
    iconVariant?: 'light' | 'solid';
    height?: number;
  };
  initialLanguage?: string;
  headers: Headers;
  onLanguageChange: (language: LanguageType) => void;
  anonymous: boolean;
}

const languages = [
  { code: 'en', name: 'English' },
  { code: 'es', name: 'Spanish' },
  { code: 'fr', name: 'French' },
  { code: 'de', name: 'German' },
  { code: 'it', name: 'Italian' },
];

export const SendMessageForm: React.FC<SendMessageFormProps> = ({
  sendMessage,
  audioSent,
  isLoading,
  translations = {} as { messageInputPlaceholder: string },
  style,
  audioUrl,
  preferencesUrl,
  headers,
  onLanguageChange,
  anonymous
}) => {
  const {
    sendMessageStyles,
    inputFieldStyles,
    sendIconStyles,
    spinnerStyles,
    iconVariant,
    color,
    height,
  } = style;

  const [text, setText] = useState('');
  const inputRef = useRef(null);
  const [popoverPlacement] = useState('top'); // Always set to 'top'
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [_isLoading, _setIsLoading] = useState(false);

  const handleSend = () => {
    if (text.length > 0) {
      sendMessage(text);
    }
    setText('');
  };
  const defaultTranslations = {
    messageInputPlaceholder: { en: 'Type message...', de: 'Nachricht eingeben...', es: 'Escribe mensaje...', fr: 'Écrire message...', it: 'Scrivi messaggio...' }
  };

  const {
    sendAudio,
    isLoading: isAudioSending,
    error: audioSendError,
  } = useAudioApi({
    audioUrl,
    headers,
  });

  const handleAudioSend = async (formData: FormData) => {
    const success = await sendAudio(formData);
    if (success) {
      audioSent();
    } else if (audioSendError) {
      Alert.alert('Audio Send Failed', audioSendError, [{ text: 'OK' }]);
    }
  };

  const sendIcon =
    iconVariant === 'light' ? (
      <FontAwesomeIcon icon={lightPaperPlane} size={24} color={'#ffffff'} />
    ) : (
      <FontAwesomeIcon icon={solidPaperPlane} color={color} />
    );

  const {
    updateLanguage,
    isLanguageUpdating: isLanguageUpdating,
    error: languageUpdateError,
    language,
  } = useLanguageApi({
    preferencesUrl,
    headers,
    anonymous
  });

  useEffect(() => {
    _setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isLanguageUpdating) {
      _setIsLoading(true);
    } else {
      _setIsLoading(false);
    }
  }, [isLanguageUpdating]);

  useEffect(() => {
    _setIsLoading(isAudioSending);
  }, [isAudioSending]);

  const handleLanguageChange = async (newLanguage: LanguageType) => {
    const success = await updateLanguage(newLanguage);
    if (success) {
      setIsPopoverOpen(false); // Close the popover after language selection
    } else if (languageUpdateError) {
      Alert.alert('Language Update Failed', languageUpdateError, [
        { text: 'OK' },
      ]);
    }
  };

  useEffect(() => {
    if (language) {
      onLanguageChange(language);
    }
  }, [language]);

  return (
    <View
      style={
        sendMessageStyles
          ? sendMessageStyles
          : {
            flexDirection: 'row',
            alignItems: 'center',
            padding: 20,
            backgroundColor: '#ebebeb',
            height,
          }
      }
    >
      <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
        <Input
          ref={inputRef}
          value={text}
          onChangeText={setText}
          size="$4"
          borderWidth={2}
          style={[
            inputFieldStyles ? inputFieldStyles : { flex: 1 },
            { paddingRight: 70 }, // Make room for the language selector
          ]}
          placeholder={translations?.messageInputPlaceholder || defaultTranslations.messageInputPlaceholder[language || 'en']}
          onSubmitEditing={handleSend}
          disabled={_isLoading}
        />
        <Popover
          placement={popoverPlacement as any}
          offset={5}
          open={isPopoverOpen}
          onOpenChange={setIsPopoverOpen}
        >
          <Popover.Trigger asChild>
            <TouchableOpacity
              style={{
                position: 'absolute',
                right: 10,
                top: '50%',
                transform: [{ translateY: -10 }],
                flexDirection: 'row',
                alignItems: 'center',
              }}
              onPress={() => setIsPopoverOpen(!isPopoverOpen)}
            >
              <Text style={{ marginRight: 5 }}>{language?.toUpperCase()}</Text>
              <FontAwesomeIcon icon={faChevronDown} size={12} color={color} />
            </TouchableOpacity>
          </Popover.Trigger>

          <Popover.Content
            borderWidth={1}
            borderColor="$borderColor"
            enterStyle={{
              y: 10,
              opacity: 0,
            }}
            exitStyle={{ y: 10, opacity: 0 }}
            elevate
            animation={[
              'quick',
              {
                opacity: {
                  overshootClamping: true,
                },
              },
            ]}
          >
            <Popover.Arrow borderWidth={1} borderColor="$borderColor" />

            <YStack padding="$3">
              {languages
                .filter((lang) => lang.code !== language)
                .map((lang) => (
                  <TouchableOpacity
                    key={lang.code}
                    onPress={() => handleLanguageChange(lang.code as LanguageType)}
                    style={{ padding: 10 }}
                  >
                    <Text fontSize={16}>
                      {lang.name} ({lang.code.toUpperCase()})
                    </Text>
                  </TouchableOpacity>
                ))}
            </YStack>
          </Popover.Content>
        </Popover>
      </View>

      {_isLoading && (
        <View marginLeft={10}>
          <Spinner style={[spinnerStyles]} color={color} />
        </View>
      )}
      {!_isLoading && text ? (
        <TouchableOpacity
          onPress={handleSend}
          disabled={_isLoading}
          style={[
            {
              width: 46,
              height: 46,
              justifyContent: 'center',
              alignItems: 'center',
            },
            sendIconStyles,
          ]}
        >
          {sendIcon}
        </TouchableOpacity>
      ) : null}
      {!_isLoading && !text && (
        <XStack alignItems="center" marginLeft={16}>
          <AudioRecorderComponent handleAudioSend={handleAudioSend} />
        </XStack>
      )}
    </View>
  );
};
